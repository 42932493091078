import React from 'react';
import { useTranslation } from 'react-i18next';
import esgPortrait from 'assets/images/esg/ESG-portrait.jpg';
import esgPortrait2 from 'assets/images/esg/ESG-portrait-2.jpg';
import esgPortrait3 from 'assets/images/esg/ESG-portrait-3.jpeg';
import Animated from 'components/generals/AnimatedComponent';
import cultureImage from 'assets/images/about-us/culture-image.png';
import respImage from 'assets/images/about-us/utp.png';

export default function ESGPage() {
    const { t } = useTranslation();

    return (
        <>
            <main className="nv__section nv__esg" id="timeline">
                <section className="nv__section__container">
                    <h1 className="nv__section__title text-center">{t('esg.title')}</h1>
                    <div className="nv__esg__picture">
                        <img src={esgPortrait} alt="ESG" className="nv__esg__portrait" />
                    </div>

                    <p>{t('esg.paragraph1')}</p>
                    <p>{t('esg.paragraph2')}</p>
                    {/* <div className="nv__esg__picture">
                    <img src={esgPortrait2} alt="ESG" className="nv__esg__portrait" />
                    </div> */}
                    <p>{t('esg.paragraph3')}</p>
                    {/* <div className="nv__esg__picture">
                    <img src={esgPortrait3} alt="ESG" className="nv__esg__portrait" />
                    </div> */}
                </section>
            </main>
        </>
    );
}
